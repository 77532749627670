import { AppBar, Button, Stack, Toolbar, Typography, useMediaQuery, useTheme, Menu, MenuItem, IconButton, ButtonProps, Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useIsLogged, useIsAdmin } from '../redux/auth/auth.slice'
import { logout } from '../redux/auth/auth.slice'
import { useDispatch } from 'react-redux'
import FontsizeSwitch from './FontsizeSwitch'
import HighcontrastSwitch from './HighcontrastSwitch'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import SystemVersion from 'src/components/SystemVersion'
import { MainLogo } from './MainLogo'
import Footer from './Footer'

export type LayoutContextType = {
    desktop: boolean
}

export const LayoutContext = React.createContext<LayoutContextType>({ desktop: true })

const ResponsiveMenuContext = React.createContext<{ handleClose?: () => void }>({})

function ResponsiveMenu({ children }: React.PropsWithChildren<{}>) {
    const { desktop } = React.useContext(LayoutContext)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <ResponsiveMenuContext.Provider value={{ handleClose }}>
        {desktop
            ? <>{children}</>
            : <>
                <IconButton onClick={handleClick}>
                    <MenuIcon sx={{ color: "whitesmoke" }}/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {children}
                </Menu>
            </>
        }

    </ResponsiveMenuContext.Provider>
}

type ResponsiveMenuItemProps = {
    onClick?: React.MouseEventHandler<HTMLElement>,
    title: string,
    href?: string
} & Pick<ButtonProps, "color" | "variant">

function ResponsiveMenuItem({ onClick, title, href, variant = "contained", color = "secondary" }: ResponsiveMenuItemProps) {
    const { desktop } = React.useContext(LayoutContext)
    const { handleClose } = React.useContext(ResponsiveMenuContext)

    const navigate = useNavigate()

    const handleClick: React.MouseEventHandler<HTMLElement> = (e: any) => {
        if (handleClose)
            handleClose()
        if (onClick)
            onClick(e)
        if (!desktop && href)
            navigate(href)
    }

    if (desktop)
        return <Button href={href} variant={variant} color={color} onClick={handleClick}>{title}</Button>

    return <MenuItem onClick={handleClick}>{title} </MenuItem>
}

export default function Layout({ children }: React.PropsWithChildren<{}>) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isLogged = useIsLogged()
    const isAdmin = useIsAdmin()
    const navigate = useNavigate()

    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    return <LayoutContext.Provider value={{ desktop }}>
        <div id='layout-root'>
            <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar variant={desktop ? 'dense' : 'regular'}>
                    <MainLogo />
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Stack direction={'row'} spacing={2}>
                        <HighcontrastSwitch />
                        <FontsizeSwitch />
                        <ResponsiveMenu>
                            {isAdmin && <ResponsiveMenuItem color="secondary" variant='text' key="admin-menu-button" href="/admin" title={t('admin')} />}
                            {isLogged
                                ? [
                                    <ResponsiveMenuItem color="info" variant='text' key="user-menu-button" href="/settings" title={t('settings')} />,
                                    <ResponsiveMenuItem color="info" variant='text' key="logout-menu-button" onClick={() => dispatch(logout())} title={t('logout')} />,
                                ]
                                : [
                                    <ResponsiveMenuItem key="login-menu-button" href="/login" title={t('login')} />,
                                    <ResponsiveMenuItem key="register-menu-button" href="/register" title={t('register')} />
                                ]
                            }
                        </ResponsiveMenu>

                    </Stack>

                </Toolbar>
            </AppBar>
            {children}
            <Footer />
        </div>
    </LayoutContext.Provider>
}
import { LatLngTuple } from "leaflet"

export type StationType = {
    position: LatLngTuple
    name: string
    number: number
    keys: string[]
    title: string,
    subtitle: string
}


export const initialStations: StationType[] = [
    {
        position: [49.95061109214193, 21.933100982942694] as LatLngTuple,
        name: "Numer platformy: 1 OPZ SmartCity13 Czujniki: temp/wilg/ciś, stacja pogodowa C, ultradźwięki",
        number: 1,
        keys: ["SmartCity13"],
        title: "Zarzecze",
        subtitle: "Most rzeki Wisłok"
    },
    {
        position: [50.011335690642454, 21.85781621611813] as LatLngTuple,
        name: "Numer platformy: 2 OPZ SmartCity15 Czujniki: temp/wilg/ciś, stacja pogodowa C, ultradźwięki",
        number: 2,
        keys: ["SmartCity15"],
        title: "Zgłobień",
        subtitle: "Most rzeki Lubcza"
    },
    {
        position: [49.979189797525954, 21.90407990047281] as LatLngTuple,
        name: "Numer platformy: 3 OPZ SmartCity14 Czujniki: temp/wilg/ciś, stacja pogodowa C, ultradźwięki",
        number: 3,
        keys: ["SmartCity14"],
        title: "Mogielnica",
        subtitle: "Mostek potoku w Mogielnicy"
    },
    {
        position: [49.98961803042607, 21.952850628216453] as LatLngTuple,
        name: "Numer platformy: 10 OPZ SmartCity07 Czujniki: PM, hałas, ultradźwiękowy, luminescencja | Numer platformy: 11 OPZ SmartCity09 Czujniki: temp/wilg/ciś, stacja pogodowa B | Numer platformy: 12 OPZ SmartCity11 Czujniki: efekt Halla, GPS",
        number: 4,
        keys: ["SmartCity08", "SmartCity09", "SmartCity11"],
        title: "ul. T. Lubomirskiego",
        subtitle: "Przy drodze T. Lubomirskiego"
    },
    {
        position: [49.98442315832029, 21.950427043847277] as LatLngTuple,
        name: "Numer platformy: 13 OPZ SmartCity08 Czujniki: PM, hałas, ultradźwiękowy, luminescencja | Numer platformy: 14 OPZ SmartCity10 Czujniki: temp/wilg/ciś, stacja pogodowa B | Numer platformy: 15 OPZ SmartCity12 Czujniki: efekt Halla, GPS",
        number: 5,
        keys: ["SmartCity07", "SmartCity10", "SmartCity12"],
        title: "ul. Grunwaldzka",
        subtitle: "Przy ulicy Grunwaldzkiej"
    },
    {
        position: [49.98175297351471, 21.93337284902352] as LatLngTuple,
        name: "Numer platformy: 7 OPZ SmartCity04 Czujniki: PM, NO2, O3, hałas | Numer platformy: 8 OPZ SmartCity05 Czujniki: stacja pogodowa A | Numer platformy: 9 OPZ SmartCity06 Czujniki: efekt Halla, GPS",
        number: 6,
        keys: ["SmartCity04", "SmartCity05", "SmartCity06"],
        title: "OSP",
        subtitle: "Na budynku OSP"

    },
    {
        position: [49.98040991048498, 21.93842665266504] as LatLngTuple,
        name: "Numer platformy: 4 OPZ SmartCity01 Czujniki: PM, NO2, O3, hałas | Numer platformy: 5 OPZ SmartCity02 Czujniki: stacja pogodowa A | Numer platformy: 6 OPZ SmartCity03 Czujniki: efekt Halla, GPS",
        number: 7,
        keys: ["SmartCity01", "SmartCity02", "SmartCity03"],
        title: "Budynek Urzędu Miejskiego",
        subtitle: "Przy Urzędzie Miejskim"
    },
]

export const camCentrumPosition = [49.98493857883594, 21.944804335326303] as LatLngTuple
export const camUrzadPosition = [49.98057896765829, 21.938309438552483] as LatLngTuple
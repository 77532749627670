/* tslint:disable */
/* eslint-disable */
/**
 * Boguchwała SmartCity API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PMSupported {
    SmartCity01 = 'SmartCity01',
    SmartCity04 = 'SmartCity04',
    SmartCity07 = 'SmartCity07',
    SmartCity08 = 'SmartCity08'
}

export function PMSupportedFromJSON(json: any): PMSupported {
    return PMSupportedFromJSONTyped(json, false);
}

export function PMSupportedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PMSupported {
    return json as PMSupported;
}

export function PMSupportedToJSON(value?: PMSupported | null): any {
    return value as any;
}


import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

export type FullLoadingProps = {
    open?: boolean
}

export default function FullLoading({ open = true }: FullLoadingProps) {
    return (
        <Backdrop
            sx={{ color: 'rgba(0, 0, 0, 0.8)', backgroundColor: 'rgba(255, 255, 255, 0.0)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import React from 'react'
import { AccountDto } from '../redux/openapi/models/AccountDto'
import { RootState } from 'src/redux/store.types'

type PrivilegesType = {
    isNOTIFICATIONS: boolean,
    isCAM: boolean,
    isMANAGE_USERS: boolean,
    isMANAGE_TRAFFIC: boolean,
    isDRONE: boolean,
    isMANAGE_MODERATORS: boolean,
    isMANAGE_GLOBAL_SETTINGS: boolean,
    isSYSTEM_ALERTS: boolean,
    isMAILING_LIST: boolean
}

type AdminCurrentState = {
    privileges: PrivilegesType
}

type AdminState = {
    current?: AdminCurrentState
}

const initialState: AdminState = {
    current: undefined
}

export const admin = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        initAdmin: (state, { payload }: PayloadAction<AccountDto>) => {
            const a = payload
            if (a.role == null)
                return initialState

            const superAdmin = a.role.includes("SUPERADMIN")
            return {
                current: {
                    privileges: {
                        isNOTIFICATIONS: superAdmin || a.role.includes("NOTIFICATIONS"),
                        isCAM: superAdmin || a.role.includes("CAM"),
                        isMANAGE_USERS: superAdmin || a.role.includes("MANAGE_USERS"),
                        isMANAGE_TRAFFIC: superAdmin || a.role.includes("MANAGE_TRAFFIC"),
                        isDRONE: superAdmin || a.role.includes("DRONE"),
                        isMANAGE_GLOBAL_SETTINGS: superAdmin || a.role.includes("MANAGE_GLOBAL_SETTINGS"),
                        isSYSTEM_ALERTS: superAdmin || a.role.includes("SYSTEM_ALERTS"),
                        isMAILING_LIST: superAdmin || a.role.includes("MAILING_LIST"),
                        isMANAGE_MODERATORS: superAdmin
                    }
                }
            }
        }
    },
})

export const { initAdmin } = admin.actions

export function usePrivileges() : PrivilegesType {
    const admin = useSelector((state: RootState) => state.admin?.current)
    return (admin)
        ? admin.privileges
        : {
            isNOTIFICATIONS: false,
            isCAM: false,
            isMANAGE_USERS: false,
            isMANAGE_TRAFFIC: false,
            isDRONE: false,
            isMANAGE_MODERATORS: false,
            isMANAGE_GLOBAL_SETTINGS: false,
            isSYSTEM_ALERTS: false,
            isMAILING_LIST: false
        }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const pathParam = (name: string) => {
    return `(?<${name}>[^\\/]+)`
}

const regexBuilder = (components: string[]) => {
    return new RegExp("^\\/" + components.join("\\/") + "\\/?.*$", "g")
}

const homeRegex = () => regexBuilder(["home", pathParam("subpage")])
const settingsRegex = () => regexBuilder(["settings"])



export default function PageTitles() {
    const { t } = useTranslation()
    const location = useLocation()

    React.useEffect(() => {
        const match = (regex: RegExp, onMatch: (match?: { [key: string]: string }) => string) => {
            let match = regex.exec(location.pathname);
            if (match != null)
                document.title = onMatch(match.groups)
            return match != null
        }

        if (match(homeRegex(), (groups) => t('mainTitle') + " - " + t(groups?.subpage as string)))
            return
        if (match(settingsRegex(), (groups) => t('mainTitle') + " - " + t('settings')))
            return

        document.title = t('mainTitle')
    }, [location])

    return <></>
}
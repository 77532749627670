import React from 'react'
import AppError from './components/AppError'
import ErrorInfo from './components/ErrorInfo'
import LinearLoader from './components/LinearLoader'
import { useGetPublicSettingsQuery } from './redux/api'
import { GlobalSettingsParam } from './redux/openapi'

export type PublicSettingsType = {
    [key: string]: GlobalSettingsParam
}

export const PublicSettingsContext = React.createContext<PublicSettingsType | undefined>(undefined)

export const useSettings = () => {
    return React.useContext(PublicSettingsContext)
}

export default function PublicSettingsProvider({children}: React.PropsWithChildren<{}>) {
    const {data, isLoading, isError, error} = useGetPublicSettingsQuery()

    const paramsMap = React.useMemo(() => {
        if(data == null || isError || isLoading)
            return undefined
        const ret : PublicSettingsType = {}
        for(const el of data.params) {
            ret[el.key] = {...el}
        }
        return ret
    }, [data, isError, isLoading])

    if(isLoading)
        return <LinearLoader/>

    if(isError || paramsMap == null)
        return <AppError details={"Błąd " + (error as any)?.status}/>

    return <PublicSettingsContext.Provider value={paramsMap}>
        {children}
    </PublicSettingsContext.Provider>
}
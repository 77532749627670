
export function toQLevel(q1: number, q50: number, m?: number) {
    //m -> m.n.p.m.
    if (m == null)
        return 0.0

    if (m >= q1)
        return 1.0

    return (m - q50) / (q1 - q50)
}

const riverStations = {
    "SmartCity15": { q1: 229.4, q50: 227.2 }, //lubcza
    "SmartCity14": { q1: 226.29, q50: 224.78 }, //mogielnicki
    "SmartCity13": { q1: 206.36, q50: 204.3 }, //wislok
}

export function toQLevelStation(station: string, m?: number) {
    //m -> m.n.p.m.

    const config = riverStations?.[station as keyof typeof riverStations]

    if(config == null)
        return 0

    return toQLevel(config.q1, config.q50, m)
}
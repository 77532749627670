import React, { Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { store, persistor } from "./redux/store"
import { Provider as ReduxProvider } from 'react-redux'
import MuiProvider from './ui/MuiProvider';
import Layout from './ui/Layout';
import { BrowserRouter, Routes as ReactDomRouter, Route } from 'react-router-dom';
import Routes from './Routes';
import AuthProvider from './redux/auth/AuthProvider';
import { PersistGate } from 'redux-persist/integration/react'
import NotistackProvider from './ui/NotistackProvider';
import { CssBaseline } from '@mui/material';
import FullLoading from './components/FullLoading';
import PageTitles from './PageTitles';
import PublicSettingsProvider from './PublicSettingsProvider';

import "moment/locale/pl"
import AdminAsUser from './admin/AdminAsUser';
import SuspenseLoader from './components/SuspenseLoader';

const TvApp = React.lazy(() => import('./tv/TvApp'))
const ClickRedirection = React.lazy(() => import('./ClickRedirection'))

function MainApp() {
  return (
    <AuthProvider>
      <Layout>
        <Routes />
      </Layout>
      <AdminAsUser />
    </AuthProvider>
  )
}

function App() {
  return (
    <Suspense fallback={<FullLoading />}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiProvider>
            <CssBaseline />
            <BrowserRouter>
              <NotistackProvider>
                  <PublicSettingsProvider>
                    <PageTitles />
                    <ReactDomRouter>
                      <Route path="/*" element={<MainApp />} />
                      <Route path="/tv_app/*" element={<TvApp />} />
                      <Route path="/click/*" element={<ClickRedirection />} />
                    </ReactDomRouter>
                  </PublicSettingsProvider>
                <SuspenseLoader/>
              </NotistackProvider>
            </BrowserRouter>
          </MuiProvider>
        </PersistGate>
      </ReduxProvider>
    </Suspense>
  );
}

export default App;

import React from 'react'
import { useSelector } from 'react-redux'
import FullLoading from '../../components/FullLoading'
import { useMeQuery } from '../api'
import { RootState } from '../store.types'

export default function AuthProvider({ children }: React.PropsWithChildren<{}>) {
    const state = useSelector((state: RootState) => state.auth)
    const { isLoading } = useMeQuery(state.accessToken, {
        skip: state.accessToken == null
    })

    if (isLoading)
        return <FullLoading />

    return <>{children}</>
}
import React from 'react'
import { createTheme, responsiveFontSizes } from "@mui/material";
import { orange } from "@mui/material/colors";
import { ThemeProvider, typography } from '@mui/system';
import { Link, LinkProps } from "react-router-dom";
import { useTheme } from './theme.slice';
import { yellow } from '@mui/material/colors';
import { useSelector } from 'react-redux';

const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <Link ref={ref} to={href} {...other} />;
});

const standardTheme = () => ({
    palette: {
        primary: {
            main: '#1e3b72',
        },
        secondary: {
            main: '#f00101'
        }
    },
    status: {
        danger: orange[500],
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {

                },
            },
        },
    },
    typography: {

    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 800,
            md: 1500,
            lg: 2000,
            xl: 2500,
        },
    },
});


export default function MuiProvider({ children }) {
    const themeState = useTheme()
    const isTvApp = useSelector((state) => state.tv.enabled)

    const theme = React.useMemo(() => {
        const t = standardTheme()

        if (isTvApp) {
            t.typography.fontSize = 10
            t.spacing = 3
        }
        else {
            if (themeState.font === "big")
                t.typography.fontSize = 16

            if (themeState.background === "contrast") {
                t.palette.mode = 'dark'
                t.palette.primary = yellow
                t.palette.divider = yellow[700]
                t.palette.text = {
                    primary: yellow[500],
                    secondary: yellow[500],
                }
                t.typography.allVariants = {
                    color: 'white'
                }
            }
        }

        return responsiveFontSizes(createTheme(t))
    }, [isTvApp, themeState.background, themeState.font])

    return <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
}
/* tslint:disable */
/* eslint-disable */
/**
 * Boguchwała SmartCity API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum Reactions {
    thumbUp = 'thumbUp',
    thumbDown = 'thumbDown',
    reaction1 = 'reaction1',
    reaction2 = 'reaction2',
    reaction3 = 'reaction3'
}

export function ReactionsFromJSON(json: any): Reactions {
    return ReactionsFromJSONTyped(json, false);
}

export function ReactionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reactions {
    return json as Reactions;
}

export function ReactionsToJSON(value?: Reactions | null): any {
    return value as any;
}


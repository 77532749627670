import { SnackbarProvider } from 'notistack';
import useNotistack from './useNotistack';

function WithNotistack({ children }: React.PropsWithChildren<{}>) {
    useNotistack();
    return <>
        {children}
    </>
}

export default function NotistackProvider({ children }: React.PropsWithChildren<{}>) {
    return <SnackbarProvider>
        <WithNotistack>
            {children}
        </WithNotistack>
    </SnackbarProvider>
}
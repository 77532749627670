import {
    createApi,
    fetchBaseQuery,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { AccountDto } from './openapi/models/AccountDto'
import { TokenDto } from './openapi/models/TokenDto'
import { RootState } from './store.types'
import { reauth, ReauthPayload, logout } from './auth/auth.slice'
import {
    RequestResultDto,
    BodyRegisterEmailConfirmationPost,
    RegisterDto,
    BodyRegisterForgotPasswordPost,
    BodyResetPasswordResetPasswordPost,
    AddModeratorRequest,
    AccountDtoForAdmin,
    ModeratorsListDto,
    UpdateModeratorRequest,
    DeleteModeratorRequest,
    ResetModeratorPasswordRequest,
    AccountNotifyDto,
    SetNotifyRequest,
    SetSmsRequest,
    SmsConfirmationRequest,
    AddNotificationRequest,
    SubscriptionsDto,
    NotificationsRequest,
    NotificationsResponse,
    SubscribedAreaDto,
    CurrentValueDto,
    PMSupported,
    PMs,
    GlobalSettingsListResponse,
    GlobalSettingsParam,
    BodyMailingListAddAdminMailingListAddPost,
    BodyMailingListDeleteAdminMailingListDeletePost,
    BodyMailingListEditAdminMailingListEditPost,
    MailingListDto,
    SystemAlertsAdminSystemAlertsPagePageLimitGetRequest,
    SystemAlertsDto,
    MeanValuesResultDto,
    TCSupported,
    PRESSupported,
    HUMSupported,
    NOISESupported,
    NotificationsAdminReponse,
    SedningNotificationsResponse,
    NotificationsStatisticsResponse,
    FindUserResponse,
    BodyFindUserAdminFindUserPagePageLimitPost,
    RFSupported,
    ConfirmNotificationRequest,
    StringReponse,
    StatisticsDto,
    ForecastDto,
    NO2Supported,
    O3Supported,
    AirForecasts,
    MonitoringListDto,
    Reactions,
    MonitoringEvent,
    RIVERSupported,
    RiverCurrentValueDto,
    RiverMeanValuesResultDto,
    AddTrafficPointDto,
    TrafficPointData,
    TrafficPointsList,
    AddDronePlanDto,
    DronePlanData,
    NotificationDto,
    WeatherForecast,
    LUXSupported,
    RAINSupported,
    WindAllPoints,
    SummaryListDto,
    AuthoritiesListDto,
    AddAuthoritiesDto,
    AuthoritiesDto
} from './openapi'
import { GetUserResponse } from './openapi/models/GetUserResponse'

type ModeratorListParams = {
    page: number,
    pageLimit: number,
    firstName?: string
    lastName?: string
    mail?: string
}

type MonitoringListParams = {
    page: number
    pageLimit: number
    type?: string
    station?: string
}

type NotificationsListParams = {
    page: number,
    pageLimit: number,
}

const baseQuery = fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.accessToken
        if (token && !headers.has('authorization')) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        const refreshToken = (api.getState() as RootState).auth.refreshToken
        const refreshResult = await baseQuery({
            url: 'refreshToken',
            method: "POST",
            headers: {
                "authorization": `Bearer ${refreshToken}`
            }
        }, api, extraOptions)
        const payload = refreshResult.data as ReauthPayload
        if (payload && payload.access_token && payload.refresh_token) {
            api.dispatch(reauth(payload))
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logout())
        }
    }
    return result
}

export const api = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ["user", "moderators", "adminNotifications", "globalSettings", "mailingList", "usersList", "currentNotifications", "monitoring", "trafficPoints", "dronePlans", "authorities"],
    keepUnusedDataFor: 30,
    endpoints: (builder) => ({
        register: builder.mutation<RequestResultDto, RegisterDto>({
            query: (body) => {
                return {
                    url: 'register',
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: ["user"]
        }),
        login: builder.mutation<TokenDto, { username: string, password: string }>({
            query: (credentials) => {
                const form = new URLSearchParams()
                form.set("username", credentials.username)
                form.set("password", credentials.password)
                return {
                    url: 'loginByMailPassword',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: form.toString()
                }
            },
            invalidatesTags: ["user"]
        }),
        me: builder.query<AccountDto, string | undefined>({
            query: () => ({
                url: 'user/me',
                method: 'GET'
            }),
            providesTags: ["user"]
        }),
        emailConfirmation: builder.mutation<RequestResultDto, BodyRegisterEmailConfirmationPost>({
            query: (body) => ({
                url: 'emailConfirmation',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ["user"]
        }),
        forgotPassword: builder.mutation<RequestResultDto, BodyRegisterForgotPasswordPost>({
            query: (body) => ({
                url: "forgotPassword",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["user"]
        }),
        resetPassword: builder.mutation<RequestResultDto, BodyResetPasswordResetPasswordPost>({
            query: (body) => ({
                url: "resetPassword",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["user"]
        }),
        moderatorsList: builder.query<ModeratorsListDto, ModeratorListParams>({
            query: (params) => {
                const { page, pageLimit, ...filterParams } = params
                return {
                    url: `admin/moderatorsList/${page}/${pageLimit}`,
                    method: "GET",
                    params: filterParams
                }
            },
            providesTags: ["moderators"]
        }),
        addModerator: builder.mutation<RequestResultDto, AddModeratorRequest>({
            query: (body) => ({
                url: "admin/addModerator",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["moderators"]
        }),
        updateModerator: builder.mutation<RequestResultDto, UpdateModeratorRequest>({
            query: (body) => ({
                url: "admin/updateModerator",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["moderators"]
        }),
        moderatorGet: builder.query<AccountDtoForAdmin, number>({
            query: (id) => (
                {
                    url: `admin/moderator/${id}`,
                    method: "GET"
                }
            ),
            providesTags: ["moderators"]
        }),
        deleteModerator: builder.mutation<RequestResultDto, DeleteModeratorRequest>({
            query: (body) => ({
                url: "admin/deleteModerator",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["moderators"]
        }),
        resetModeratorPassword: builder.mutation<RequestResultDto, ResetModeratorPasswordRequest>({
            query: (body) => ({
                url: "admin/resetModeratorPassword",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["moderators"]
        }),
        getNotifications: builder.query<AccountNotifyDto, void>({
            query: () => ({
                url: 'user/settings/notify',
                method: 'GET'
            }),
            providesTags: ["user"]
        }),
        setNotifications: builder.mutation<RequestResultDto, SetNotifyRequest>({
            query: (body) => ({
                url: 'user/settings/setNotify',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ["user"]
        }),
        setSms: builder.mutation<RequestResultDto, SetSmsRequest>({
            query: (body) => ({
                url: "/user/sms/set",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["user"]
        }),
        confirmSms: builder.mutation<RequestResultDto, SmsConfirmationRequest>({
            query: (body) => ({
                url: "/user/sms/confirm",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["user"]
        }),
        removeSms: builder.mutation<RequestResultDto, void>({
            query: (body) => ({
                url: "/user/sms/remove",
                method: "POST"
            }),
            invalidatesTags: ["user"]
        }),
        addNotification: builder.mutation<RequestResultDto, AddNotificationRequest>({
            query: (body) => ({
                url: "/admin/notifications/add",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["adminNotifications"]
        }),
        confirmNotification: builder.mutation<RequestResultDto, ConfirmNotificationRequest>({
            query: (body) => ({
                url: "/admin/notifications/confirm",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["adminNotifications"]
        }),
        deactivateNotification: builder.mutation<RequestResultDto, number>({
            query: (notificationId) => ({
                url: `/admin/notifications/deactivate/${notificationId}`,
                method: "PUT"
            }),
            invalidatesTags: ["adminNotifications"]
        }),
        getSubscriptions: builder.query<SubscriptionsDto, void>({
            query: () => ({
                url: 'user/settings/subscriptions',
                method: 'GET'
            }),
            providesTags: ["user"]
        }),
        setSubscriptions: builder.mutation<RequestResultDto, SubscriptionsDto>({
            query: (body) => ({
                url: 'user/settings/setSubscriptions',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ["user"]
        }),
        deleteAccount: builder.mutation<RequestResultDto, void>({
            query: (body) => ({
                url: 'user/deleteAccount',
                method: 'delete'
            }),
            invalidatesTags: ["user"]
        }),
        getCurrentNotifications: builder.query<NotificationsResponse, NotificationsRequest>({
            query: (body) => ({
                url: 'notifications',
                method: 'POST',
                body: body
            }),
            providesTags: ["currentNotifications"]
        }),
        getAllNotifications: builder.query<NotificationsResponse, void>({
            query: () => ({
                url: 'notifications/all',
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
            providesTags: ["currentNotifications"]
        }),
        getOneNotification: builder.query<NotificationDto, number>({
            query: (notificationId) => ({
                url: `notifications/one/${notificationId}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
            providesTags: ["currentNotifications"]
        }),
        setReaction: builder.mutation<RequestResultDto, { notificationId: number, value: Reactions }>({
            query: ({ notificationId, value }) => ({
                url: `notifications/reaction/${notificationId}/${value}`,
                method: 'PUT'
            }),
            invalidatesTags: ["currentNotifications"]
        }),
        getSubscribedArea: builder.query<SubscribedAreaDto, void>({
            query: () => ({
                url: 'user/settings/subscribedArea',
                method: 'GET'
            }),
            providesTags: ["user"]
        }),
        setSubscribedArea: builder.mutation<RequestResultDto, SubscribedAreaDto>({
            query: (body) => ({
                url: 'user/settings/setSubscribedArea',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ["user"]
        }),
        getDataCurrentPm: builder.query<CurrentValueDto, { station: PMSupported, pm: PMs }>({
            query: ({ station, pm }) => ({
                url: `data/pm/current/${station}/${pm}`,
                method: 'GET'
            }),
        }),
        getDataHistoryPm: builder.query<MeanValuesResultDto, { station: PMSupported, pm: PMs }>({
            query: ({ station, pm }) => ({
                url: `data/pm/history/${station}/${pm}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataStatsPm: builder.query<StatisticsDto, { station: PMSupported, pm: PMs }>({
            query: ({ station, pm }) => ({
                url: `data/pm/statistics/${station}/${pm}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataAirForecasts: builder.query<AirForecasts, { station: string }>({
            query: ({ station }) => ({
                url: `data/air/forecast/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataCurrentNo2: builder.query<CurrentValueDto, { station: NO2Supported }>({
            query: ({ station }) => ({
                url: `data/no2/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataCurrentO3: builder.query<CurrentValueDto, { station: O3Supported }>({
            query: ({ station }) => ({
                url: `data/o3/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataStatsNo2: builder.query<StatisticsDto, { station: NO2Supported }>({
            query: ({ station }) => ({
                url: `data/no2/statistics/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataHistoryNo2: builder.query<MeanValuesResultDto, { station: NO2Supported }>({
            query: ({ station }) => ({
                url: `data/no2/history/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataHistoryO3: builder.query<MeanValuesResultDto, { station: O3Supported }>({
            query: ({ station }) => ({
                url: `data/o3/history/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataStatsO3: builder.query<StatisticsDto, { station: O3Supported }>({
            query: ({ station }) => ({
                url: `data/o3/statistics/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getGlobalSettings: builder.query<GlobalSettingsListResponse, void>({
            query: () => ({
                url: "admin/globalSettings",
                method: 'GET'
            }),
            providesTags: ["globalSettings"]
        }),
        setGlobalSetting: builder.mutation<RequestResultDto, GlobalSettingsParam>({
            query: (body) => ({
                url: 'admin/setGlobalSetting',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ["globalSettings"]
        }),
        getPublicSettings: builder.query<GlobalSettingsListResponse, void>({
            query: () => ({
                url: "publicSettings",
                method: 'GET'
            }),
            providesTags: ["globalSettings"]
        }),
        addMailingList: builder.mutation<RequestResultDto, BodyMailingListAddAdminMailingListAddPost>({
            query: (body) => ({
                url: "admin/mailingList/add",
                method: 'POST',
                body
            }),
            invalidatesTags: ["mailingList"]
        }),
        deleteFromMailingList: builder.mutation<RequestResultDto, BodyMailingListDeleteAdminMailingListDeletePost>({
            query: (body) => ({
                url: "admin/mailingList/delete",
                method: 'POST',
                body
            }),
            invalidatesTags: ["mailingList"]
        }),
        editMailingList: builder.mutation<RequestResultDto, BodyMailingListEditAdminMailingListEditPost>({
            query: (body) => ({
                url: "admin/mailingList/edit",
                method: 'POST',
                body
            }),
            invalidatesTags: ["mailingList"]
        }),
        getMailingList: builder.query<MailingListDto, void>({
            query: () => ({
                url: "admin/mailingList",
                method: 'GET'
            }),
            providesTags: ["mailingList"]
        }),
        getSystemAlerts: builder.query<SystemAlertsDto, SystemAlertsAdminSystemAlertsPagePageLimitGetRequest>({
            query: (params) => {
                const { page, pageLimit, ...filterParams } = params
                return {
                    url: `admin/systemAlerts/${page}/${pageLimit}`,
                    method: 'GET',
                    params: filterParams
                }
            }
        }),
        getDataCurrentTemperature: builder.query<CurrentValueDto, TCSupported>({
            query: (station) => ({
                url: `data/temperature/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataCurrentPressure: builder.query<CurrentValueDto, PRESSupported>({
            query: (station) => ({
                url: `data/pressure/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataCurrentHumidity: builder.query<CurrentValueDto, HUMSupported>({
            query: (station) => ({
                url: `data/humidity/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataCurrentNoise: builder.query<CurrentValueDto, NOISESupported>({
            query: (station) => ({
                url: `data/noise/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataHistoryNoise: builder.query<MeanValuesResultDto, NOISESupported>({
            query: (station) => ({
                url: `data/noise/history/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataForecastNoise: builder.query<ForecastDto, NOISESupported>({
            query: (station) => ({
                url: `data/noise/forecast/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataStatsNoise: builder.query<StatisticsDto, { station: NOISESupported }>({
            query: ({ station }) => ({
                url: `data/noise/statistics/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getNotificationsList: builder.query<NotificationsAdminReponse, NotificationsListParams>({
            query: ({ page, pageLimit }) => ({
                url: `admin/notificationsList/${page}/${pageLimit}`,
                method: 'POST'
            }),
            providesTags: ["adminNotifications"]
        }),
        getSendingNotifications: builder.query<SedningNotificationsResponse, void>({
            query: () => ({
                url: `admin/sendingNotifications`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
            providesTags: ["adminNotifications"]
        }),
        getNotificationsStatistics: builder.query<NotificationsStatisticsResponse, void>({
            query: () => ({
                url: `admin/notifications/stats`,
                method: 'GET'
            })
        }),
        findUser: builder.query<FindUserResponse, { page: number, pageLimit: number, body: BodyFindUserAdminFindUserPagePageLimitPost }>({
            query: ({ page, pageLimit, body }) => ({
                url: `admin/findUser/${page}/${pageLimit}`,
                method: 'POST',
                body
            }),
            providesTags: ["usersList"]
        }),
        getUser: builder.query<GetUserResponse, number>({
            query: (userId) => ({
                url: `admin/getUser/${userId}`,
                method: 'GET'
            })
        }),
        getDataCurrentRf: builder.query<CurrentValueDto, RFSupported>({
            query: (station) => ({
                url: `data/rf/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataHistoryRf: builder.query<MeanValuesResultDto, RFSupported>({
            query: (station) => ({
                url: `data/rf/history/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataForecastRf: builder.query<ForecastDto, RFSupported>({
            query: (station) => ({
                url: `data/rf/forecast/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataStatsRf: builder.query<StatisticsDto, { station: RFSupported }>({
            query: ({ station }) => ({
                url: `data/rf/statistics/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getUserToken: builder.mutation<TokenDto, number>({
            query: (userId) => ({
                url: `admin/userToken/${userId}`,
                method: 'GET'
            }),
        }),
        getSystemVersion: builder.query<StringReponse, void>({
            query: () => ({
                url: `systemVerions`,
                method: 'GET'
            })
        }),
        monitoringList: builder.query<MonitoringListDto, MonitoringListParams>({
            query: (params) => {
                const { page, pageLimit, ...filterParams } = params
                return {
                    url: `/admin/monitoringList/${page}/${pageLimit}`,
                    method: "GET",
                    params: filterParams
                }
            },
            providesTags: ["monitoring"]
        }),
        monitoringView: builder.query<MonitoringEvent, { id: number }>({
            query: (params) => {
                const { id } = params
                return {
                    url: `/admin/monitoring/view/${id}`,
                    method: "GET"
                }
            },
            providesTags: ["monitoring"]
        }),
        monitoringToken: builder.query<string, void>({
            query: () => {
                return {
                    url: `/admin/monitoring/token`,
                    method: "GET"
                }
            },
            providesTags: ["user"]
        }),
        monitoringStart: builder.mutation<RequestResultDto, { station: string }>({
            query: ({ station }) => ({
                url: `/admin/monitoring/manual/start/${station}`,
                method: 'PUT'
            }),
            invalidatesTags: ["monitoring"]
        }),
        monitoringStop: builder.mutation<RequestResultDto, { station: string }>({
            query: ({ station }) => ({
                url: `/admin/monitoring/manual/stop/${station}`,
                method: 'PUT'
            }),
            invalidatesTags: ["monitoring"]
        }),
        monitoringManualStatus: builder.query<RequestResultDto, { station: string }>({
            query: ({ station }) => {
                return {
                    url: `/admin/monitoring/manual/check/${station}`,
                    method: "GET"
                }
            },
            providesTags: ["monitoring"]
        }),
        monitoringStopById: builder.mutation<RequestResultDto, { id: number }>({
            query: ({ id }) => ({
                url: `/admin/monitoring/stop/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: ["monitoring"]
        }),
        getDataCurrentRiver: builder.query<RiverCurrentValueDto, RIVERSupported>({
            query: (station) => ({
                url: `data/river/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataHistoryRiver: builder.query<RiverMeanValuesResultDto, RIVERSupported>({
            query: (station) => ({
                url: `data/river/history/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getDataStatsRiver: builder.query<StatisticsDto, { station: RIVERSupported }>({
            query: ({ station }) => ({
                url: `data/river/statistics/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        addTrafficPoint: builder.mutation<RequestResultDto, AddTrafficPointDto>({
            query: (body) => ({
                url: `/admin/traffic/point/add`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["trafficPoints"]
        }),
        updateTrafficPoint: builder.mutation<RequestResultDto, { id: number, body: AddTrafficPointDto }>({
            query: ({ id, body }) => ({
                url: `/admin/traffic/point/update/${id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["trafficPoints"]
        }),
        getTrafficPoints: builder.query<TrafficPointData[], void>({
            query: () => {
                return {
                    url: `/admin/traffic/list`,
                    method: "GET"
                }
            },
            providesTags: ["trafficPoints"]
        }),
        getTrafficPoint: builder.query<TrafficPointData | undefined, number>({
            query: (id) => {
                return {
                    url: `/admin/traffic/one/${id}`,
                    method: "GET"
                }
            },
            providesTags: ["trafficPoints"]
        }),
        setTrafficPointEnabled: builder.mutation<RequestResultDto, { id: number, enabled: boolean }>({
            query: ({ id, enabled }) => ({
                url: `/admin/traffic/enable/${id}/${enabled}`,
                method: 'PUT'
            }),
            invalidatesTags: ["trafficPoints"]
        }),
        deleteTrafficPoint: builder.mutation<RequestResultDto, number>({
            query: (id) => ({
                url: `/admin/traffic/delete/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ["trafficPoints"]
        }),
        getTrafficCurrent: builder.query<TrafficPointsList | undefined, void>({
            query: () => {
                return {
                    url: `/data/traffic/current`,
                    method: "GET"
                }
            },
            providesTags: ["trafficPoints"]
        }),
        addDronePlan: builder.mutation<RequestResultDto, AddDronePlanDto>({
            query: (body) => ({
                url: `/admin/drone/plan/add`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["dronePlans"]
        }),
        updateDronePlan: builder.mutation<RequestResultDto, { id: number, body: AddDronePlanDto }>({
            query: ({ id, body }) => ({
                url: `/admin/drone/plan/update/${id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["dronePlans"]
        }),
        getDronePlans: builder.query<DronePlanData[], void>({
            query: () => {
                return {
                    url: `/admin/drone/plan/list`,
                    method: "GET"
                }
            },
            providesTags: ["dronePlans"]
        }),
        getDronePlan: builder.query<DronePlanData, number>({
            query: (id) => {
                return {
                    url: `/admin/drone/plan/one/${id}`,
                    method: "GET"
                }
            },
            providesTags: ["dronePlans"]
        }),
        deleteDronPlan: builder.mutation<RequestResultDto, number>({
            query: (id) => ({
                url: `/admin/drone/plan/delete/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ["dronePlans"]
        }),
        getWeatherForecast: builder.query<WeatherForecast | undefined, string>({
            query: (field) => {
                return {
                    url: `/data/weather/forecast/${field}`,
                    method: "GET"
                }
            }
        }),
        getDataCurrentLux: builder.query<CurrentValueDto, LUXSupported>({
            query: (station) => ({
                url: `data/lux/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataCurrentRain: builder.query<CurrentValueDto, RAINSupported>({
            query: (station) => ({
                url: `data/rain/current/${station}`,
                method: 'GET'
            }),
        }),
        getDataCurrentWind: builder.query<WindAllPoints, void>({
            query: () => ({
                url: `data/wind/current`,
                method: 'GET'
            }),
        }),
        uploadDroneSummary: builder.mutation<RequestResultDto, { form: FormData, planId: number }>({
            query: ({ form, planId }) => ({
                url: `/admin/drone/summary/upload/${planId}`,
                method: 'POST',
                body: form
            }),
            invalidatesTags: ["dronePlans"]
        }),
        getSummaryFile: builder.query<any, number>({
            query: (summaryId) => ({
                url: `/admin/drone/summary/file/${summaryId}`,
                method: 'GET',
                responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
                cache: "no-cache",
            }),
            providesTags: ["dronePlans"]
        }),
        getSummaryList: builder.query<SummaryListDto, { page: number, pageLimit: number, planId: number }>({
            query: ({page, pageLimit, planId}) => ({
                url: `/admin/drone/summary/list/${planId}/${page}/${pageLimit}`,
                method: 'GET'
            }),
            providesTags: ["dronePlans"]
        }),
        getDataForecastRiver: builder.query<ForecastDto, RIVERSupported>({
            query: (station) => ({
                url: `data/river/forecast/${station}`,
                method: 'GET',
                keepUnusedDataFor: 30,
            }),
        }),
        getAuthoritiesList: builder.query<AuthoritiesListDto, {page: number, pageLimit: number}>({
            query: ({page, pageLimit}) => ({
                url: `admin/authorities/list/${page}/${pageLimit}`,
                method: 'GET',
            }),
            providesTags: ["authorities"]
        }),
        addAuthoritiesList: builder.mutation<RequestResultDto, AddAuthoritiesDto>({
            query: (body) => ({
                url: `admin/authorities/add`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["authorities"]
        }),
        updateAuthoritiesList: builder.mutation<RequestResultDto, {rowId: number, body: AddAuthoritiesDto}>({
            query: ({rowId, body}) => ({
                url: `admin/authorities/update/${rowId}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["authorities"]
        }),
        deleteAuthoritiesList: builder.mutation<RequestResultDto, number>({
            query: (rowId) => ({
                url: `admin/authorities/delete/${rowId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ["authorities"]
        }),
        getAuthoritiesGroups: builder.query<string[], void>({
            query: () => ({
                url: `admin/authorities/groups`,
                method: 'GET',
            }),
            providesTags: ["authorities"]
        }),
        getAuthoritiesOne: builder.query<AuthoritiesDto, number>({
            query: (rowId) => ({
                url: `admin/authorities/one/${rowId}`,
                method: 'GET',
            }),
            providesTags: ["authorities"]
        }),
        deleteAccountByAdmin: builder.mutation<RequestResultDto, number>({
            query: (userId) => ({
                url: `admin/deleteUser/${userId}`,
                method: 'delete'
            }),
            invalidatesTags: ["usersList"]
        }),
        getMailById: builder.query<string | undefined, number>({
            query: (userId) => ({
                url: `admin/notifications/getMailById/${userId}`,
                method: 'GET',
            })
        }),
    }),
})

export const {
    useRegisterMutation,
    useLoginMutation,
    useMeQuery,
    useEmailConfirmationMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useModeratorsListQuery,
    useAddModeratorMutation,
    useUpdateModeratorMutation,
    useModeratorGetQuery,
    useDeleteModeratorMutation,
    useResetModeratorPasswordMutation,
    useGetNotificationsQuery,
    useSetNotificationsMutation,
    useSetSmsMutation,
    useConfirmSmsMutation,
    useRemoveSmsMutation,
    useAddNotificationMutation,
    useConfirmNotificationMutation,
    useGetSubscriptionsQuery,
    useSetSubscriptionsMutation,
    useGetCurrentNotificationsQuery,
    useGetSubscribedAreaQuery,
    useSetSubscribedAreaMutation,
    useGetDataCurrentPmQuery,
    useGetDataHistoryPmQuery,
    useGetDataStatsPmQuery,
    useGetDataAirForecastsQuery,
    useGetDataCurrentNo2Query,
    useGetDataStatsNo2Query,
    useGetDataCurrentO3Query,
    useGetDataHistoryNo2Query,
    useGetDataHistoryO3Query,
    useGetDataStatsO3Query,
    useGetGlobalSettingsQuery,
    useSetGlobalSettingMutation,
    useGetPublicSettingsQuery,
    useAddMailingListMutation,
    useDeleteFromMailingListMutation,
    useEditMailingListMutation,
    useGetMailingListQuery,
    useGetSystemAlertsQuery,
    useGetDataCurrentTemperatureQuery,
    useGetDataCurrentPressureQuery,
    useGetDataCurrentHumidityQuery,
    useGetDataCurrentNoiseQuery,
    useGetDataHistoryNoiseQuery,
    useGetDataForecastNoiseQuery,
    useGetNotificationsListQuery,
    useGetSendingNotificationsQuery,
    useGetNotificationsStatisticsQuery,
    useFindUserQuery,
    useGetUserQuery,
    useGetDataCurrentRfQuery,
    useGetDataForecastRfQuery,
    useGetDataHistoryRfQuery,
    useGetUserTokenMutation,
    useGetSystemVersionQuery,
    useMonitoringListQuery,
    useSetReactionMutation,
    useMonitoringTokenQuery,
    useMonitoringViewQuery,
    useMonitoringStartMutation,
    useMonitoringStopMutation,
    useMonitoringManualStatusQuery,
    useMonitoringStopByIdMutation,
    useGetDataStatsNoiseQuery,
    useGetDataStatsRfQuery,
    useGetDataCurrentRiverQuery,
    useGetDataHistoryRiverQuery,
    useUpdateTrafficPointMutation,
    useAddTrafficPointMutation,
    useGetTrafficPointQuery,
    useGetTrafficPointsQuery,
    useSetTrafficPointEnabledMutation,
    useDeleteTrafficPointMutation,
    useGetTrafficCurrentQuery,
    useAddDronePlanMutation,
    useUpdateDronePlanMutation,
    useGetDronePlansQuery,
    useGetDronePlanQuery,
    useDeleteDronPlanMutation,
    useGetAllNotificationsQuery,
    useGetDataStatsRiverQuery,
    useGetOneNotificationQuery,
    useGetWeatherForecastQuery,
    useDeactivateNotificationMutation,
    useGetDataCurrentLuxQuery,
    useGetDataCurrentRainQuery,
    useGetDataCurrentWindQuery,
    useUploadDroneSummaryMutation,
    useGetSummaryFileQuery,
    useGetSummaryListQuery,
    useGetDataForecastRiverQuery,
    useGetAuthoritiesListQuery,
    useAddAuthoritiesListMutation,
    useUpdateAuthoritiesListMutation,
    useDeleteAuthoritiesListMutation,
    useGetAuthoritiesGroupsQuery,
    useGetAuthoritiesOneQuery,
    useDeleteAccountMutation,
    useDeleteAccountByAdminMutation,
    useGetMailByIdQuery
} = api

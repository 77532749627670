import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams"
import moment from "moment"
import React from "react"
import { useParams } from "react-router-dom"

export function formatNumber(value: number) {
    let maximumSignificantDigits = 2
    if(value > 99) {
        maximumSignificantDigits = 4
    }
    else if(value > 999)
        maximumSignificantDigits = 5
    return Intl.NumberFormat('pl-PL', { maximumSignificantDigits }).format(value)
}

export function formatLatLng(value: number) {
    let maximumSignificantDigits = 6
    return Intl.NumberFormat('pl-PL', { maximumSignificantDigits }).format(value)
}

export function useParamId(idKey: string) {
    const params = useParams()

    const idStr = React.useMemo(() => params?.[idKey], [idKey, params])

    const asInt = React.useMemo(() => {
        if (idStr == null)
            return undefined

        const i = parseInt(idStr)
        if (isNaN(i))
            return undefined
        return i
    }, [idStr])

    return asInt
}

export function kelvin2Celsius(value: number) {
    return value - 273.15
}

const alpha = Array.from(Array(26)).map((e, i) => i + 97);
export const alphabet = alpha.map((x) => String.fromCharCode(x));

export function alphabetKey(index: number) {
    let ret = ""
    do {
        ret = alphabet[index % alphabet.length] + ret
        index = Math.floor(index / alphabet.length)
    }while(index > 0)
    return ret
}

export function TimeFormatter(params: GridRenderCellParams<any, any, any>) {
    const time = React.useMemo(() => {
        const timeString = params.value?.valueOf() as string | undefined
        if (timeString)
            return moment(timeString).format("DD-MM-YYYY HH:mm:ss")
    }, [params.value])

    return <>{time}</>
}

export function TimeFormatterUTC(params: GridRenderCellParams<any, any, any>) {
    const time = React.useMemo(() => {
        const timeString = params.value?.valueOf() as string | undefined
        if (timeString)
            return moment.utc(timeString).local().format("DD-MM-YYYY HH:mm:ss")
    }, [params.value])

    return <>{time}</>
}
import { Alert, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loginAsUserStop, TokensType } from "src/redux/auth/auth.slice";
import { AccountDto } from "src/redux/openapi";
import { RootState } from "src/redux/store.types";

function AdminBox({ userAuth }: {userAuth: AccountDto}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    
    const onClick = () => {
        dispatch(loginAsUserStop())
        setTimeout(() => {
            window.location.reload()
        }, 200)
    }


    return <Alert
        sx={{ position: 'absolute', bottom: 5, left: '50%', zIndex: 10000, transform: 'translateX(-50%)' }}
        variant="filled"
        severity="success"
        action={
            <Button color="inherit" size="small" onClick={onClick}>
                {t('backToAdmin')}
            </Button>
        }
    >
        {t('adminBoxAsUser', {userName: userAuth.mail})}
    </Alert>
}

export default function AdminAsUser() {
    const adminAuth = useSelector((state: RootState) => state.auth.adminAsUser)
    const userAuth: AccountDto | undefined = useSelector((state: RootState) => state.auth.user)

    if (adminAuth == null || userAuth == null)
        return <></>

    return <AdminBox userAuth={userAuth}/>
}
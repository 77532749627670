import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { SnackbarKey, SnackbarMessage } from 'notistack';

interface IEnqueueOptions {
    /** Type of the snackbar */
    variant: 'default' | 'error' | 'success' | 'warning' | 'info'
    /** Event fired when user clicks on action button (if any) */
    onClickAction(): void
    /**
     * You can pass material-ui Snackbar props here, and they will be applied to this individual snackbar.
     * for example, this particular snackbar will be dismissed after 1sec.
     */
    autoHideDuration: number
}

export type NotificationType = {
    message: SnackbarMessage,
    options?: Partial<IEnqueueOptions>,
    dismissed?: boolean,
    key?: SnackbarKey
}


type SnackbarState = {
    notifications: NotificationType[]
}

const initialState: SnackbarState = {
    notifications: []
}

export const snackbar = createSlice({
    name: 'snackbar',
    initialState: initialState,
    reducers: {
        enqueue: (state, { payload }: PayloadAction<NotificationType>) => {
            state.notifications.push({
                message: payload.message,
                key: payload?.key ? payload.key : new Date().getTime() + Math.random(),
                options: payload?.options,
                dismissed: false
            })
        },
        close: (state) => {
            for(const one of state.notifications)
                one.dismissed = true
        },
        remove: (state, {payload}: PayloadAction<SnackbarKey>) => {
            state.notifications = state.notifications.filter(el => el.key !== payload)
        }
    },
})

export const { enqueue, close, remove } = snackbar.actions

export function useSnackbar() {
    const dispatch = useDispatch()

    return {
        enqueueSnackbar: (params: NotificationType) => dispatch(enqueue(params)),
        closeSnackbars: () => dispatch(close()),
        removeSnackbar: (key: SnackbarKey) => dispatch(remove(key))
    }
}
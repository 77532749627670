import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';
import { RootState } from 'src/redux/store.types';
import { NotificationType, remove } from 'src/redux/snackbar.slice';

let displayed: SnackbarKey[] = [];

export default function useNotistack() {
    const dispatch = useDispatch();
    const notifications = useSelector((store: RootState) => store.snackbar.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false }: NotificationType) => {
            if (dismissed) {
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (key && displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(remove(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            if (key)
                storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};


import { Box, LinearProgress } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from 'src/redux/api'
import { setGlobalLoading } from 'src/redux/general.slice'
import { RootState } from 'src/redux/store.types'

function GlobalProgress() {
    return <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 20000 }}>
        <LinearProgress />
    </Box>
}

export default function SuspenseLoader({ children }: React.PropsWithChildren<{}>) {
    const dispatch = useDispatch()
    const { globalIsLoading } = useSelector((state: RootState) => state.general)
    React.useEffect(() => {
        const check = async () => {
            let isTrigerred = false
            const delayedStart = setTimeout(() => { isTrigerred = true; dispatch(setGlobalLoading(true)); }, 50)
            await Promise.all(api.util.getRunningOperationPromises())
            if (isTrigerred) {
                dispatch(setGlobalLoading(false))
            } else {
                clearTimeout(delayedStart)
            }
            setTimeout(check, 200)
        }
        check()
    }, [dispatch])

    return <>
        {globalIsLoading && <GlobalProgress />}
    </>
}
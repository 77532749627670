import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function AppError({details}: {details?: string}) {
    const { t } = useTranslation()
    return <Container component="main" maxWidth="xs">
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
            }}
        >
            <Avatar sx={{ m: 1, p: 1, bgcolor: 'error.main' }}>
                <ErrorOutlineIcon />
            </Avatar>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography component="h1" variant="h3">
                    {t('mainTitle')}
                </Typography>
                <Typography component="h5" variant="h5">
                    {t('appError')}
                </Typography>
                {details && <Typography component="p" variant="body2">
                    {details}
                </Typography>}
            </Stack>
        </Box>
    </Container>
}
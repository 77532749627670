import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStations } from 'src/redux/Stations'
import { Modes } from 'src/views/HomePage/homePage.slice'

export const tvAppModes = [
    "pm-current",
    "pm-forecast",
    "pm-long",
    "pm-stat",
    "el-current",
    "el-forecast",
    "el-long",
    "el-stat",
    "hydro-current",
    "hydro-forecast",
    "hydro-long",
    "hydro-stat",
    "sound-current",
    "sound-forecast",
    "sound-long",
    "sound-stat",
    "crowd",
    "traffic",
    "other"
] as const

export type TvAppModes = typeof tvAppModes[number]

export type TvStateType = {
    current: number,
    enabled: boolean
    mapMode: Modes,
    showMap: boolean
}

export const initialState: TvStateType = {
    current: 3,
    enabled: false,
    mapMode: "pm-current",
    showMap: false
}

export const tv = createSlice({
    name: 'tv',
    initialState: initialState,
    reducers: {
        next: (state) => {
            const length = state.showMap ? tvAppModes.length : initialStations.length
            if (state.current < length - 1)
                state.current++
            else
                state.current = 0

            if(state.showMap) {
                state.mapMode = tvAppModes[state.current]
            }
        },
        setTvApp: (state, { payload: enabled }: PayloadAction<boolean>) => {
            if (!enabled)
                return initialState
            return { ...initialState, enabled: true }
        },
        resetCounter: (state) => {
            state.current = 0
        },
        setTvMapMode: (state, { payload: mode }: PayloadAction<Modes>) => {
            state.mapMode = mode
        },
        setShowMap: (state, { payload: show }: PayloadAction<boolean>) => {
            state.showMap = show
        },
    },
})

export const { next, setTvApp, resetCounter, setTvMapMode, setShowMap } = tv.actions
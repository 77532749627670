import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../redux/store.types'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

export type FontOptions = "normal" | "big"
export type BackgroundOptions = "normal" | "contrast"

export type ThemeState = {
    font: FontOptions
    background: BackgroundOptions
}

const initialState: ThemeState = {
    font: "normal",
    background: "normal"
}

export const theme = createSlice({
    name: 'theme',
    initialState: initialState,
    reducers: {
        setFont: (state, { payload }: PayloadAction<FontOptions>) => {
            state.font = payload
        },
        setBackground: (state, { payload }: PayloadAction<BackgroundOptions>) => {
            state.background = payload
        }
    }
})

export const { setFont, setBackground } = theme.actions
export function useTheme(): ThemeState {
    return useSelector((state: RootState) => state.theme)
}


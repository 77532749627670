import { Grid, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import { AppName } from 'src/names'
import { RootState } from 'src/redux/store.types'
import logo from './smartcity_small.png'

export function ImageLogo() {
    return <img src={logo} alt={AppName} style={{ height: 50 }} />
}

export function MainLogo() {
    const { enabled: isTvApp } = useSelector((state: RootState) => state.tv)

    return <Box
        sx={{
            cursor: 'pointer',
        }}
    >
        <Grid container spacing={2}>
            <Grid item sx={{ transform: 'translateY(4px)' }}>
                <a href="/"><ImageLogo /></a>
            </Grid>
            <Grid item sx={{ justifyContent: 'center', flexDirection: 'column', display: { xs: isTvApp ? 'flex' : 'none', md: 'flex' } }}>
                <Typography
                    variant="h6"
                    component="a"
                    href="/"
                    sx={{
                        fontWeight: 700,
                        letterSpacing: '.1rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    {AppName}
                </Typography>
            </Grid>
        </Grid>
    </Box>
}


import React from 'react'
import { styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export type TooltipHeaderTypes = "current" | "history"

export const TooltipHeaderContext = React.createContext<TooltipHeaderTypes | undefined>(undefined)

export default function TooltipHeader({ type }: { type: TooltipHeaderTypes }) {
    const { t } = useTranslation()
    return <Typography sx={{ marginBottom: 0, marginTop: 0 }} variant="overline" component="div">{t(`tooltipHeader.${type}`)}:</Typography>
}

export function TooltipHeaderFromContext() {
    const type = React.useContext(TooltipHeaderContext)

    if (type == null)
        return <></>

    return <TooltipHeader type={type} />
}

export function TooltipHeaderProvider({ type, children }: React.PropsWithChildren<{ type: TooltipHeaderTypes }>) {
    return <TooltipHeaderContext.Provider value={type}>{children}</TooltipHeaderContext.Provider>
}
import React from "react"
import { StationState } from "../homePage.slice"

export function getSupportedStationKey(SupportedEnum: { [s: string]: string }, stationKeys?: string[]) {
    if(stationKeys == null || stationKeys?.length === 0)
        return undefined
    for(const key of stationKeys) {
        if(key && Object.values<string>(SupportedEnum).includes(key))
            return SupportedEnum[key]
    }
    return undefined  
}

export function useSupportedStationKey(SupportedEnum: { [s: string]: string }, station?: StationState) {
    return React.useMemo(() => {
        return getSupportedStationKey(SupportedEnum, station?.keys)
    }, [SupportedEnum, station?.keys])
}

import React from 'react'
import { useTheme, setBackground } from './theme.slice'
import ContrastIcon from '@mui/icons-material/Contrast';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';

export default function HighcontrastSwitch() {
    const themeState = useTheme()
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setBackground(themeState.background == "contrast" ? "normal" : "contrast"))
    }

    return <IconButton onClick={onClick}>
        <ContrastIcon sx={t => ({ color: themeState.background === "contrast" ? t.palette.text.primary : "whitesmoke"  })}/>
    </IconButton>
}
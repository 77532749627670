import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type GeneralState = {
    globalIsLoading: boolean
}

const initialState: GeneralState = {
    globalIsLoading: false
}

export const general = createSlice({
    name: 'general',
    initialState: initialState,
    reducers: {
        setGlobalLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.globalIsLoading = payload
        }
    },
})

export const { setGlobalLoading } = general.actions

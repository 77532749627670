import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../api'
import type { RootState } from '../store.types'
import { AccountDto } from '../openapi/models/AccountDto'
import { useSelector } from 'react-redux'
import React from 'react'

export type TokensType = {
    accessToken?: string,
    refreshToken?: string,
}

export type AuthState = TokensType & {
    user?: AccountDto,
    adminAsUser?: TokensType
}

const initialState: AuthState = {
    accessToken: undefined,
    refreshToken: undefined,
    user: undefined,
    adminAsUser: undefined
}

export type ReauthPayload = { refresh_token: string, access_token: string }

export const auth = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        reauth: (state, { payload }: PayloadAction<ReauthPayload>) => {
            state.accessToken = payload.access_token
            state.refreshToken = payload.refresh_token
        },
        logout: () => {
            //handled by rootReducer
        },
        loginAsUserStart: (state, { payload }: PayloadAction<TokensType>) => {
            state.adminAsUser = payload
        },
        loginAsUserStop: (state, { payload }: PayloadAction<undefined>) => {
            state.accessToken = state.adminAsUser?.accessToken
            state.refreshToken = state.adminAsUser?.refreshToken
            state.adminAsUser = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
            state.accessToken = payload.access_token
            state.refreshToken = payload.refresh_token
        })
        builder.addMatcher(api.endpoints.me.matchFulfilled, (state, { payload }) => {
            state.user = payload
        })
    },
})

export const { reauth, logout, loginAsUserStart, loginAsUserStop } = auth.actions

export const selectCurrentUser = (state: RootState) => state.auth.user
export const useIsLogged = () => {
    const user = useSelector(selectCurrentUser)
    return React.useMemo(() => user != null, [user])
}
export const useIsAdmin = () => {
    const user = useSelector(selectCurrentUser)
    return user?.role != null
}
/* tslint:disable */
/* eslint-disable */
/**
 * Boguchwała SmartCity API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PMs {
    PM1 = 'PM1',
    PM2_5 = 'PM2_5',
    PM10 = 'PM10'
}

export function PMsFromJSON(json: any): PMs {
    return PMsFromJSONTyped(json, false);
}

export function PMsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PMs {
    return json as PMs;
}

export function PMsToJSON(value?: PMs | null): any {
    return value as any;
}


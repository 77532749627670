/* tslint:disable */
/* eslint-disable */
/**
 * Boguchwała SmartCity API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum HUMSupported {
    SmartCity01 = 'SmartCity01',
    SmartCity04 = 'SmartCity04',
    SmartCity07 = 'SmartCity07',
    SmartCity08 = 'SmartCity08',
    SmartCity13 = 'SmartCity13',
    SmartCity14 = 'SmartCity14',
    SmartCity15 = 'SmartCity15'
}

export function HUMSupportedFromJSON(json: any): HUMSupported {
    return HUMSupportedFromJSONTyped(json, false);
}

export function HUMSupportedFromJSONTyped(json: any, ignoreDiscriminator: boolean): HUMSupported {
    return json as HUMSupported;
}

export function HUMSupportedToJSON(value?: HUMSupported | null): any {
    return value as any;
}


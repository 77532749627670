import chroma from "chroma-js";
import { FieldType, StationState } from "./homePage.slice";

type GradientColors = {
    colorStart: string
    colorEnd: string
}

export const gradientColorList = ['rgba(87,177,8,0.8)', 'rgba(176,221,16,0.8)', 'rgba(255,217,17,0.8)', 'rgba(229,129,0,0.8)', 'rgba(229,0,0,0.8)', 'rgba(151,0,0,0.8)']
export const gradientFactory = chroma.scale(gradientColorList);

export function fieldGradient(color: chroma.Color, alpha: number = 0.4): GradientColors {
    return {
        colorStart: color.brighten(2).darken().alpha(alpha).hex("rgba"),
        colorEnd: color.desaturate(2).alpha(0.0).hex("rgba")
    }
}

export function threeLevelGradient(value: number, good?: number, warning?: number, alert?: number, alpha?: number) {
    if (!good || !warning || !alert)
        return { colorStart: '', colorEnd: '' }

    let v = 0.0;

    if (value <= good)
        v = 0.0
    else if (value <= warning) {
        const a = 0.5 / (warning - good)
        const b = -a * good
        v = a * value + b
    } else if (value <= alert) {
        const a = 0.5 / (alert - warning)
        const b = 0.5 - a * warning
        v = a * value + b
    } else {
        v = 1.0
    }

    v = Math.max(0.0, Math.min(1.0, v))

    const baseColor = gradientFactory(v);
    return fieldGradient(baseColor, alpha)
}

export function multiLevelGradient(value: number, tresholds: number[], alpha?: number, colorList: string[] = gradientColorList) {
    if (tresholds.length < 2)
        return { colorStart: '', colorEnd: '' }

    const color = (() => {
        const l = colorList.length
        for (let i = 0; i < l; i++) {
            if (value < tresholds[i]) {
                return colorList[(i < l) ? i : l - 1]
            }
        }
        return colorList[l - 1]
    })()

    const baseColor = chroma(color)
    return fieldGradient(baseColor, alpha)
}

export type GradientPushFunction = (station: StationState, gradientColors: (value: number) => GradientColors, getParam: (station: StationState) => number | undefined) => void

export function gradientsForEachStation(
    stations: StationState[],
    addFunc: (push: GradientPushFunction, station: StationState) => void
) {
    const array: FieldType[] = []
    const push: GradientPushFunction = (station, gradientColors, getParam) => {
        const param = getParam(station)
        if (param) {
            array.push({
                position: station.position,
                radius: 1500,
                ...gradientColors(param)
            })
        }
    }

    for (const station of stations) {
        addFunc(push, station)
    }
    return array
}

export type LegendTreshold = {
    label: string,
    color: string,
    max?: number
}

export function legendTresholds(t1?: number, t2?: number, t3?: number, t4?: number, t5?: number): LegendTreshold[] {
    return [
        {
            label: "Bardzo dobry",
            color: gradientColorList[0],
            max: t1
        },
        {
            label: "Dobry",
            color: gradientColorList[1],
            max: t2
        },
        {
            label: "Umiarkowany",
            color: gradientColorList[2],
            max: t3
        },
        {
            label: "Dostateczny",
            color: gradientColorList[3],
            max: t4
        },
        {
            label: "Zły",
            color: gradientColorList[4],
            max: t5
        },
        {
            label: "Bardzo zły",
            color: gradientColorList[5],
            max: undefined
        }
    ]
}

export function findLegendLevel(tresholds: LegendTreshold[], value: number) {
    for(let i = 0; i < tresholds.length - 1; i++) {
        const level = tresholds[i]
        if (level.max && value < level.max)
            return level
    }
    return tresholds[tresholds.length - 1]
}
/* tslint:disable */
/* eslint-disable */
/**
 * Boguchwała SmartCity API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum RIVERSupported {
    SmartCity13 = 'SmartCity13',
    SmartCity14 = 'SmartCity14',
    SmartCity15 = 'SmartCity15'
}

export function RIVERSupportedFromJSON(json: any): RIVERSupported {
    return RIVERSupportedFromJSONTyped(json, false);
}

export function RIVERSupportedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RIVERSupported {
    return json as RIVERSupported;
}

export function RIVERSupportedToJSON(value?: RIVERSupported | null): any {
    return value as any;
}


import React from 'react'
import { useTheme, setFont } from './theme.slice'
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';

export default function FontsizeSwitch() {
    const themeState = useTheme()
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setFont(themeState.font === "big" ? "normal" : "big"))
    }

    return <IconButton onClick={onClick}>
        <FormatSizeIcon sx={t => ({ color: themeState.font === "big" ? t.palette.secondary.main : "whitesmoke"  })}/>
    </IconButton>
}
import React, { Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { store } from "./redux/store"
import { Provider as ReduxProvider } from 'react-redux'
import MuiProvider from './ui/MuiProvider';
import Layout from './ui/Layout';
import { BrowserRouter, Routes as ReactDomRoutes, Route, Navigate } from 'react-router-dom';
import { useIsAdmin, useIsLogged } from './redux/auth/auth.slice';

const HomePage = React.lazy(() => import('./views/HomePage/HomePage'));
const LoginPage = React.lazy(() => import('./views/LoginPage/LoginPage'));
const EmailConfirmation = React.lazy(() => import('./views/EmailConfirmation/EmailConfirmation'))
const RegisterPage = React.lazy(() => import('./views/RegisterPage/RegisterPage'))
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/ResetPassword/ResetPassword'))
const SettingsPage = React.lazy(() => import('./views/SettingsPage/SettingsPage'))

const AdminHome = React.lazy(() => import('./admin/AdminHome'))

export default function Routes() {
    const isLogged = useIsLogged()
    const isAdmin = useIsAdmin()
    return <ReactDomRoutes>

        <Route path="/home" element={<Navigate replace to="/home/pm-current" />} />
        <Route path="/home/:mode" element={<HomePage />} />

        {!isLogged && <Route path="/login" element={<LoginPage />} />}
        {!isLogged && <Route path="/register" element={<RegisterPage />} />}
        {!isLogged && <Route path="/forgotPassword" element={<ForgotPassword />} />}

        <Route path="/email/:token" element={<EmailConfirmation />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />

        {isLogged && <Route path="/settings" element={<SettingsPage />} />}

        {isAdmin && <Route path="/admin/*" element={<AdminHome />} />}

        <Route
            path="*"
            element={<Navigate replace to="/home" />}
        />
    </ReactDomRoutes>
}
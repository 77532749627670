/* tslint:disable */
/* eslint-disable */
/**
 * Boguchwała SmartCity API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TCSupported {
    SmartCity01 = 'SmartCity01',
    SmartCity04 = 'SmartCity04',
    SmartCity07 = 'SmartCity07',
    SmartCity08 = 'SmartCity08',
    SmartCity13 = 'SmartCity13',
    SmartCity14 = 'SmartCity14',
    SmartCity15 = 'SmartCity15'
}

export function TCSupportedFromJSON(json: any): TCSupported {
    return TCSupportedFromJSONTyped(json, false);
}

export function TCSupportedFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCSupported {
    return json as TCSupported;
}

export function TCSupportedToJSON(value?: TCSupported | null): any {
    return value as any;
}


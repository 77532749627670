import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal';
import { LatLngTuple } from 'leaflet'
import { AirForecasts, ForecastDto, ForecastPoint, HUMSupported, LUXSupported, NOISESupported, NotificationDto, PRESSupported, RAINSupported, RFSupported, RIVERSupported, TCSupported } from 'src/redux/openapi';
import { initialStations, StationType } from 'src/redux/Stations';
import { GeoJsonObject } from 'geojson'
import moment from 'moment';
import { timestampFromStringLocal } from './HistoryGraph';
import { toQLevelStation } from './MapModes/hydro/toQLevel';
import { getSupportedStationKey } from './MapModes/supportedStationKey';
import { FeatureCollection, Point, Properties } from '@turf/turf';

export enum AirDataTypes {
    PM1 = 'PM1',
    PM2_5 = 'PM2_5',
    PM10 = 'PM10',
    NO2 = "NO2",
    O3 = "O3"
}

export type StationPmCurrent = {
    PM1?: number
    PM10?: number
    PM2_5?: number,
    NO2?: number,
    O3?: number
}

export type StatsValues = {
    daily?: number
    monthly?: number
    yearly?: number
}

export type StationPmStats = {
    PM1?: number
    PM10?: number
    PM2_5?: number
    NO2?: number
    O3?: number
    PM1_stats?: StatsValues
    PM10_stats?: StatsValues
    PM2_5_stats?: StatsValues
    NO2_stats?: StatsValues
    O3_stats?: StatsValues
}

export type StationPmForecasts = {
    PM1?: number
    PM10?: number
    PM2_5?: number
    NO2?: number
    O3?: number
    PM1_forecast?: ForecastPoint[]
    PM10_forecast?: ForecastPoint[]
    PM2_5_forecast?: ForecastPoint[]
    NO2_forecast?: ForecastPoint[]
    O3_forecast?: ForecastPoint[]
}

export type StationNoiseCurrent = {
    noise?: number
}

export type StationNoiseStats = {
    noise?: number
    noise_stats?: StatsValues
}

export type StationNoiseForecasts = {
    noise?: number
    noise_forecast?: ForecastPoint[]
}

export type StationRfCurrent = {
    rf?: number
}

export type StationRfStats = {
    rf?: number
    rf_stats?: StatsValues
}

export type StationRfForecasts = {
    rf?: number
    rf_forecast?: ForecastPoint[]
}

export type StationHydroCurrent = {
    q?: number
    m?: number
}

export type StationHydroStats = {
    m?: number
    q?: number
    m_stats?: StatsValues
}

export type StationHydroForecasts = {
    m?: number
    q?: number
    m_forecast?: ForecastPoint[]
}


export function isStationRfCurrent(obj?: any): obj is StationNoiseCurrent {
    const o = obj as StationRfCurrent
    return o?.rf !== undefined
}

export function isStationNoiseCurrent(obj?: any): obj is StationNoiseCurrent {
    const o = obj as StationNoiseCurrent
    return o?.noise !== undefined
}

export function isStationPmCurrent(obj?: any): obj is StationPmCurrent {
    const o = obj as StationPmCurrent
    return o?.PM1 !== undefined && o?.PM10 !== undefined && o?.PM2_5 !== undefined
}

export function isStatsValues(obj?: any): obj is StationPmCurrent {
    const o = obj as StatsValues
    return o?.daily !== undefined && o?.monthly !== undefined && o?.yearly !== undefined
}

export function isStationPmStats(obj?: any): obj is StationPmStats {
    const o = obj as StationPmStats
    return o?.PM1 !== undefined && o?.PM10 !== undefined && o?.PM2_5 !== undefined && isStatsValues(o?.PM1_stats) && isStatsValues(o?.PM10_stats) && isStatsValues(o?.PM2_5_stats)
}

export function isStationRfStats(obj?: any): obj is StationRfStats {
    const o = obj as StationRfStats
    return o?.rf !== undefined && isStatsValues(o?.rf_stats)
}

export function isStationNoiseStats(obj?: any): obj is StationNoiseStats {
    const o = obj as StationNoiseStats
    return o?.noise !== undefined && isStatsValues(o?.noise_stats)
}

export function isStationPmForcasts(obj?: any): obj is StationPmForecasts {
    const o = obj as StationPmForecasts
    return o?.PM1 !== undefined && o?.PM10 !== undefined && o?.PM2_5 !== undefined && o?.PM1_forecast !== undefined && o?.PM10_forecast !== undefined && o?.PM2_5_forecast !== undefined
}

export function isStationRfForcasts(obj?: any): obj is StationRfForecasts {
    const o = obj as StationRfForecasts
    return o?.rf !== undefined && o?.rf_forecast !== undefined
}

export function isStationNoiseForecasts(obj?: any): obj is StationNoiseForecasts {
    const o = obj as StationNoiseForecasts
    return o?.noise !== undefined && o?.noise_forecast !== undefined
}

export function isStationHydroCurrent(obj?: any): obj is StationHydroCurrent {
    const o = obj as StationHydroCurrent
    return o?.q !== undefined && o?.m !== undefined
}

export function isStationHydroStats(obj?: any): obj is StationHydroStats {
    const o = obj as StationHydroStats
    return o?.m !== undefined && isStatsValues(o?.m_stats) && o?.q !== undefined
}

export function isStationHydroForecasts(obj?: any): obj is StationHydroForecasts {
    const o = obj as StationHydroForecasts
    return o?.m !== undefined && o?.m_forecast !== undefined && o?.q !== undefined
}

export type StationState = StationType & {
    currentModeData?: (
        StationPmCurrent |
        StationNoiseCurrent |
        StationRfCurrent |
        StationPmStats |
        StationPmForecasts |
        StationRfStats |
        StationNoiseStats |
        StationRfForecasts |
        StationNoiseForecasts |
        StationHydroCurrent
    ),
    temperature?: number,
    pressure?: number,
    humidity?: number,
    forecastTime?: string,
    rain?: number,
    lux?: number,
    windSpeed?: number
}

export type MarkerType = {
    id: number
    position: LatLngTuple
}

export type FieldType = {
    position: LatLngTuple,
    radius: number //meters
    colorStart: string
    colorEnd: string
}

export type Modes = "pm-current" | "pm-forecast"
    | "pm-long" | "pm-stat" | "el-current" | "el-forecast" | "el-long" | "el-stat" | "hydro-current" | "hydro-forecast" | "hydro-long" | "hydro-stat"
    | "sound-current" | "sound-forecast" | "sound-long" | "sound-stat" | "crowd" | "traffic" | "other";

export type LegendType = {
    value?: number
    color: string
}

export type GeojsonDataType = {
    data: GeoJsonObject,
    key: string,
    defaultColor: string
}

export type HomePageState = {
    stations: StationState[],
    showStations: boolean,
    fields: FieldType[],
    notifications: NotificationDto[],
    notificationsIsLoading: boolean,
    markers: MarkerType[],
    selectedNotification?: NotificationDto
    legend: LegendType[],
    geojson?: GeojsonDataType,
    freeMarker?: LatLngTuple,
    forecastStep: number,
    showFields: boolean,
    showWinds: boolean,
    windsInterpolation?: FeatureCollection<Point, Properties>,
    additionalMarker?: LatLngTuple
}

const initialState: HomePageState = {
    stations: initialStations,
    showStations: false,
    fields: [],
    notifications: [],
    notificationsIsLoading: false,
    markers: [],
    selectedNotification: undefined,
    legend: [],
    geojson: undefined,
    freeMarker: undefined,
    forecastStep: 1,
    showFields: true,
    showWinds: false,
    windsInterpolation: undefined,
    additionalMarker: undefined
}

export function findStation(state: WritableDraft<HomePageState>, station: any, onFind: (station: WritableDraft<StationState>) => void) {
    const index = state.stations.findIndex(el => el.keys.includes(station as string))
    if (index >= 0) {
        onFind(state.stations[index])
    }
}

export function firstTimestampInTheFuture(offset: number, data?: ForecastPoint[]) {
    if (data == null)
        return undefined
    let current = moment().valueOf()
    current += offset * 60 * 60 * 1000
    for (let i = 0; i < data.length; i++) {
        const timestamp = timestampFromStringLocal(data[i].timestamp)
        const diff = timestamp - current
        if (diff > 0) {
            return data[i].timestamp
        }

    }
    return data[data.length - 1].timestamp
}

function forcasedValueAt(timestamp?: string, data?: ForecastPoint[]) {
    if (data == null || timestamp == null)
        return undefined
    for (let i = 0; i < data.length; i++) {
        if (timestamp === data[i].timestamp)
            return data[i].value
    }
    return undefined
}

export const homePage = createSlice({
    name: 'homePage',
    initialState: initialState,
    reducers: {
        clearMap: () => {
            return initialState
        },
        setNotifications: (state, { payload }: PayloadAction<{ data: NotificationDto[], isLoading: boolean }>) => {
            state.notificationsIsLoading = payload.isLoading;
            state.notifications = payload.data
            state.markers = payload.data.filter(el => el.lat != null && el.lng != null).map(el => ({
                id: el.id,
                position: [el.lat, el.lng] as LatLngTuple
            }))
        },
        selectMarker: (state, { payload }: PayloadAction<number | undefined>) => {
            if (payload == null)
                state.selectedNotification = undefined
            else if (state.selectedNotification && state.selectedNotification.id === payload)
                state.selectedNotification = undefined
            else state.selectedNotification = state.notifications.find(el => el.id === payload)
        },
        setSelectedNotification: (state, { payload }: PayloadAction<NotificationDto>) => {
            state.selectedNotification = payload
        },
        setPm: (state, { payload }: PayloadAction<{ station: string, dataType: AirDataTypes, value: number | undefined }>) => {
            const { station, dataType, value } = payload
            findStation(state, station, (station) => {
                const current = (station.currentModeData ? station.currentModeData : {}) as any
                station.currentModeData = {
                    PM1: current?.PM1,
                    PM10: current?.PM10,
                    PM2_5: current?.PM2_5,
                    NO2: current?.NO2,
                    O3: current?.O3,
                    [dataType]: value
                }
            })
        },
        setPmStats: (state, { payload }: PayloadAction<{ station: string, dataType: AirDataTypes, flatten: number | undefined, value: StatsValues | undefined }>) => {
            const { station, dataType, value, flatten } = payload
            findStation(state, station, (station) => {
                const current = (station.currentModeData ? station.currentModeData : {}) as StationPmStats
                station.currentModeData = {
                    PM1: current?.PM1,
                    PM10: current?.PM10,
                    PM2_5: current?.PM2_5,
                    NO2: current?.NO2,
                    O3: current?.O3,
                    PM1_stats: current?.PM1_stats,
                    PM10_stats: current?.PM10_stats,
                    PM2_5_stats: current?.PM2_5_stats,
                    NO2_stats: current?.NO2_stats,
                    O3_stats: current?.O3_stats,
                    [`${dataType}_stats`]: value,
                    [dataType]: flatten
                }
            })
        },
        setPmForecasts: (state, { payload }: PayloadAction<{ station: string, data?: AirForecasts }>) => {
            const { station, data } = payload
            findStation(state, station, (station) => {
                const current = (station.currentModeData ? station.currentModeData : {}) as StationPmForecasts
                const offset = state.forecastStep
                const forecastTime = firstTimestampInTheFuture(offset, data?.pm1)
                station.currentModeData = {
                    PM1: forcasedValueAt(forecastTime, data?.pm1),
                    PM10: forcasedValueAt(forecastTime, data?.pm10),
                    PM2_5: forcasedValueAt(forecastTime, data?.pm25),
                    NO2: forcasedValueAt(forecastTime, data?.no2),
                    O3: forcasedValueAt(forecastTime, data?.o3),
                    PM1_forecast: data?.pm1 ?? current?.PM1_forecast,
                    PM10_forecast: data?.pm25 ?? current?.PM10_forecast,
                    PM2_5_forecast: data?.pm10 ?? current?.PM2_5_forecast,
                    NO2_forecast: data?.no2 ?? current?.NO2_forecast,
                    O3_forecast: data?.o3 ?? current?.O3_forecast
                }
                station.forecastTime = forecastTime
            })
        },
        setNoise: (state, { payload }: PayloadAction<{ station: NOISESupported, value: number | undefined }>) => {
            const { station, value } = payload
            findStation(state, station, (station) => {
                station.currentModeData = {
                    noise: value
                } as StationNoiseCurrent
            })
        },
        setNoiseStats: (state, { payload }: PayloadAction<{ station: string, flatten: number | undefined, value: StatsValues | undefined }>) => {
            const { station, value, flatten } = payload
            findStation(state, station, (station) => {
                station.currentModeData = {
                    noise: flatten,
                    noise_stats: value
                } as StationNoiseStats
            })
        },
        setNoiseForecasts: (state, { payload }: PayloadAction<{ station: string, data?: ForecastDto }>) => {
            const { station, data } = payload
            findStation(state, station, (station) => {
                const offset = state.forecastStep
                const forecastTime = firstTimestampInTheFuture(offset, data?.values)
                station.currentModeData = {
                    noise: forcasedValueAt(forecastTime, data?.values),
                    noise_forecast: data?.values
                } as StationNoiseForecasts
                station.forecastTime = forecastTime
            })
        },
        setHydroForecasts: (state, { payload }: PayloadAction<{ station: string, data?: ForecastDto }>) => {
            const { station, data } = payload
            findStation(state, station, (station) => {
                const offset = state.forecastStep
                const forecastTime = firstTimestampInTheFuture(offset, data?.values)
                const m = forcasedValueAt(forecastTime, data?.values)
                const stationKey = getSupportedStationKey(RIVERSupported, station.keys)
                if (stationKey) {
                    station.currentModeData = {
                        m: m,
                        q: toQLevelStation(stationKey, m),
                        m_forecast: data?.values
                    } as StationHydroForecasts
                    station.forecastTime = forecastTime
                }

            })
        },
        setRf: (state, { payload }: PayloadAction<{ station: RFSupported, value: number | undefined }>) => {
            const { station, value } = payload
            findStation(state, station, (station) => {
                station.currentModeData = {
                    rf: value
                } as StationRfCurrent
            })
        },
        setRfStats: (state, { payload }: PayloadAction<{ station: string, flatten: number | undefined, value: StatsValues | undefined }>) => {
            const { station, value, flatten } = payload
            findStation(state, station, (station) => {
                station.currentModeData = {
                    rf: flatten,
                    rf_stats: value
                } as StationRfStats
            })
        },
        setRfForecasts: (state, { payload }: PayloadAction<{ station: string, data?: ForecastDto }>) => {
            const { station, data } = payload
            findStation(state, station, (station) => {
                const offset = state.forecastStep
                const forecastTime = firstTimestampInTheFuture(offset, data?.values)
                station.currentModeData = {
                    rf: forcasedValueAt(forecastTime, data?.values),
                    rf_forecast: data?.values
                } as StationRfForecasts
                station.forecastTime = forecastTime
            })
        },
        setRiver: (state, { payload }: PayloadAction<{ station: RIVERSupported, q: number | undefined, m: number | undefined }>) => {
            const { station, q, m } = payload
            findStation(state, station, (station) => {
                station.currentModeData = {
                    q: (q ?? 0),
                    m
                } as StationHydroCurrent
            })
        },
        setHydroStats: (state, { payload }: PayloadAction<{ station: string, flatten: number | undefined, value: StatsValues | undefined }>) => {
            const { station, value, flatten } = payload
            findStation(state, station, (station) => {
                const stationKey = getSupportedStationKey(RIVERSupported, station.keys)
                if (stationKey) {
                    station.currentModeData = {
                        m: flatten,
                        q: toQLevelStation(stationKey, flatten),
                        m_stats: value
                    } as StationHydroStats
                }
                
            })
        },
        clearHydroStats: (state, { payload }: PayloadAction<{ station: string }>) => {
            findStation(state, payload.station, (station) => {
                station.currentModeData = undefined
            })
        },
        setLegend: (state, { payload }: PayloadAction<LegendType[]>) => {
            state.legend = [...payload]
        },
        setTemperature: (state, { payload }: PayloadAction<{ station: TCSupported, value: number | undefined }>) => {
            const { station, value } = payload
            findStation(state, station, (station) => {
                station.temperature = value
            })
        },
        setPressure: (state, { payload }: PayloadAction<{ station: PRESSupported, value: number | undefined }>) => {
            const { station, value } = payload
            findStation(state, station, (station) => {
                station.pressure = value
            })
        },
        setHumidity: (state, { payload }: PayloadAction<{ station: HUMSupported, value: number | undefined }>) => {
            const { station, value } = payload
            findStation(state, station, (station) => {
                station.humidity = value
            })
        },
        setGeojson: (state, { payload }: PayloadAction<{ geojson: any, defaultColor: string }>) => {
            const newKey = `${(new Date().getTime())}`
            state.geojson = {
                data: payload.geojson,
                key: newKey,
                defaultColor: payload.defaultColor
            }
        },
        hideGeojson: (state) => {
            state.geojson = undefined
        },
        setStaionsVisible: (state, { payload }: PayloadAction<boolean>) => {
            state.showStations = payload
        },
        setFreeMarker: (state, { payload }: PayloadAction<LatLngTuple | undefined>) => {
            state.freeMarker = payload
        },
        setAdditionalMarker: (state, { payload }: PayloadAction<LatLngTuple | undefined>) => {
            state.additionalMarker = payload
        },
        setForecastStep: (state, { payload }: PayloadAction<number>) => {
            state.forecastStep = payload
        },
        setShowFields: (state, { payload }: PayloadAction<boolean>) => {
            state.showFields = payload
        },
        setShowWinds: (state, { payload }: PayloadAction<boolean>) => {
            state.showWinds = payload
        },
        setRain: (state, { payload }: PayloadAction<{ station: RAINSupported, value: number | undefined }>) => {
            const { station, value } = payload
            findStation(state, station, (station) => {
                station.rain = value
            })
        },
        setLux: (state, { payload }: PayloadAction<{ station: LUXSupported, value: number | undefined }>) => {
            const { station, value } = payload
            findStation(state, station, (station) => {
                station.lux = value
            })
        },
        setWindSpeed: (state, { payload }: PayloadAction<{ station: string, value: number | undefined }>) => {
            const { station, value } = payload
            findStation(state, station, (station) => {
                station.windSpeed = value
            })
        },
        setWindInterpolation: (state, { payload }: PayloadAction<FeatureCollection<Point, Properties> | undefined>) => {
            state.windsInterpolation = payload
        },
    },
})

export const {
    clearMap,
    setNotifications,
    selectMarker,
    setPm,
    setPmStats,
    setPmForecasts,
    setLegend,
    setTemperature,
    setPressure,
    setHumidity,
    setNoise,
    setNoiseStats,
    setRf,
    setRfStats,
    setGeojson,
    hideGeojson,
    setStaionsVisible,
    setFreeMarker,
    setForecastStep,
    setRfForecasts,
    setNoiseForecasts,
    setRiver,
    setHydroStats,
    clearHydroStats,
    setShowFields,
    setSelectedNotification,
    setRain,
    setLux,
    setShowWinds,
    setHydroForecasts,
    setWindSpeed,
    setWindInterpolation,
    setAdditionalMarker
} = homePage.actions

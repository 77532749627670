import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { api } from './api'
import { auth } from './auth/auth.slice'
import { theme } from '../ui/theme.slice'
import { snackbar } from './snackbar.slice'
import { homePage } from 'src/views/HomePage/homePage.slice';
import { admin } from '../admin/admin.slice'
import { tv } from '../tv/tv.slice'
import { general } from './general.slice'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['auth', 'theme']
}

const combinedReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: auth.reducer,
    theme: theme.reducer,
    snackbar: snackbar.reducer,
    homePage: homePage.reducer,
    admin: admin.reducer,
    tv: tv.reducer,
    general: general.reducer
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'auth/logout') {
      state = undefined;
    }
    return combinedReducer(state, action);
  };

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat(api.middleware)
});

export let persistor = persistStore(store)

import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from 'src/redux/store.types'
import unia from './unia.png'

const height = 100

const ContainerBox = styled(Box)(({ theme }) => ({
    color: theme.palette.grey[500],
    zIndex: 1000,
    fontSize: (theme.typography as any).body2.fontSize
}))

const FixedBoxToLeft = styled(ContainerBox)(({ theme }) => ({
    position: "fixed",
    bottom: "0px",
    left: "20vw",
    transform: "translateX(5px)",
}))

const FixedBoxCentered = styled(ContainerBox)(({ theme }) => ({
    position: "fixed",
    bottom: "0px",
    left: "50%",
    transform: "translateX(-50%)",
}))

const AdminBox = styled(ContainerBox)(({ theme }) => ({
    position: "relative",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: -height,
    height: height
}))

const StaticBox = styled(ContainerBox)(({ theme }) => ({
    position: "relative",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 30
}))

const TvBox = styled(ContainerBox)(({ theme }) => ({
    position: "fixed",
    top: 0,
    right: 0,
    transformOrigin: "top right",
    [theme.breakpoints.down("md")]: {
        transform: "scale(1)"
    }
}))

export default function Footer() {
    const location = useLocation();
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const { enabled: isTvApp } = useSelector((state: RootState) => state.tv)

    const imgStyle = React.useMemo(() => {
        return desktop
            ? ({
                height: 60
            })
            : ({
                height: 40,
                marginBottom: 15
            })
    }, [desktop])

    const TheBox = React.useMemo(() => {
        if (isTvApp)
            return TvBox
        if (location.pathname.startsWith("/home"))
            return desktop ? FixedBoxToLeft : FixedBoxCentered
        if (location.pathname.startsWith("/admin"))
            return AdminBox
        return StaticBox
    }, [desktop, isTvApp, location.pathname])

    return <TheBox>
        <img style={imgStyle} src={unia} alt="Fundusze Europejskie"></img>
    </TheBox>
}
import { Box, Button, ButtonGroup, useTheme } from '@mui/material'
import React from 'react'
import { Bar } from 'react-chartjs-2';
import chroma from "chroma-js";
import moment from "moment";
import {
    Chart as ChartJS,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip as ChartTooltip,
    Legend,
    Filler,
    TooltipItem,
    BarElement
} from 'chart.js';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import { formatNumber } from 'src/Utils';
import Tooltip from '@mui/material/Tooltip';
import { findLegendLevel, gradientColorList, LegendTreshold } from './legendGradient';
import { useSettings } from 'src/PublicSettingsProvider';
import { TooltipHeaderFromContext } from 'src/components/TooltipHeader';

ChartJS.register(
    TimeScale, //Register timescale instead of category for X axis
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    ChartTooltip,
    Legend,
    Filler,
    annotationPlugin
);

export const historyGraphGradient = chroma.scale(['rgba(3, 127, 252, 0.8)', 'rgba(20, 125, 181, 0.8)']);

export function timestampFromString(str: string) {
    return moment.utc(str, 'DD-MM-YYYY HH:mm:ss').valueOf()
}

export function timestampFromStringLocal(str: string) {
    return moment(str, 'DD-MM-YYYY HH:mm:ss').valueOf()
}

export function timestampToUtc(str: string) {
    return moment(str, 'DD-MM-YYYY HH:mm:ss').local().utc().format('DD-MM-YYYY HH:mm:ss')
}

const commonDatasetOptions: any = {
    radius: 2,
    fill: true,
    cubicInterpolationMode: 'monotone',
    backgroundColor: 'rgba(0, 255, 255, 1.0)'
}

export type HistoryDatasets = {
    [key: string]: { x: number, y: number }[]
}

export type Boundaries = {
    min: number
    max: number
}

const useChartOptions = (unit: string, annotations?: any, isTvApp: boolean = false, bound: Boundaries | undefined = undefined): any => {
    const theme = useTheme()

    return React.useMemo(() => ({
        responsive: true,
        animation: true,
        parsing: false,
        maintainAspectRatio: true,

        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context: TooltipItem<any>) {
                        let label = context.dataset.label || '';
                        if (context.parsed.y !== null) {
                            var formatted = formatNumber(context.parsed.y)
                            label += `: ${formatted} ${unit}`;
                        }
                        return label;
                    },
                    title: function (context: TooltipItem<any>[]) {
                        return moment((context[0].raw as any).x).format("DD-MM-YYYY HH:mm")
                    }
                }
            },
            ...(annotations ?? {})
        },

        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        scales: {
            x: {
                type: 'time',
                ticks: {
                    source: 'auto',
                    // Disabled rotation for performance
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 5,
                    color: theme.palette.text.primary,
                },
                time: {
                    displayFormats: {
                        millisecond: "HH:mm:ss",
                        second: "HH:mm",
                        minute: "HH:mm",
                        hour: "HH:mm",
                    }
                },
                grid: {
                    display: false
                },

            },
            y: {
                display: true,
                stacked: false,
                title: {
                    display: true,
                    text: `[${unit}]`,
                    font: {
                        size: 10,
                        padding: 0
                    }
                },
                grid: {
                    display: true
                },
                ticks: {
                    source: 'auto',
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 5,
                    font: {
                        size: 9
                    },
                    padding: 0,
                    backdropPadding: 0,
                    callback: function (value: number, index: number, ticks: number) {
                        return formatNumber(value)
                    },
                },
                suggestedMin: bound?.min,
                suggestedMax: bound?.max,
                beginAtZero: bound == null,
            }
        },
        barPercentage: 1,
        categoryPercentage: 1
    }), [annotations, bound, theme.palette.text.primary, unit])
}

export type SingleDatasetInfoType = {
    label: string,
    legend: LegendTreshold[]
    defaultColor?: string
}
export type DatasetsInfoType = {
    [key: string]: SingleDatasetInfoType
}

export type HistoryGraphProps = { 
    datasets?: HistoryDatasets, 
    unit: string, 
    datasetsInfo: DatasetsInfoType, 
    isTvApp?: boolean, 
    bound?: Boundaries
}

const contrastColor = (color: string) => {
    if (color == null)
        return 'black'
    const c = chroma(color)
    const chromaColor = (c.luminance() > 0.5) ? c.darken(3) : c.brighten(3)
    return chromaColor.alpha(1).hex()
}

export default function HistoryGraph({ datasets, unit, datasetsInfo, isTvApp = false, bound = undefined }: HistoryGraphProps) {
    const [selected, setSelected] = React.useState<string | undefined>()
    const options = useChartOptions(unit, undefined, isTvApp, bound)

    const keys = React.useMemo(() => Object.keys(datasetsInfo), [datasetsInfo])
    const info = React.useMemo(() => keys.reduce((acc, key) => {
        if (datasets?.[key] != null)
            acc.push({
                key,
                ...datasetsInfo[key]
            })
        return acc
    }, [] as (SingleDatasetInfoType & { key: string })[]), [datasets, datasetsInfo, keys])

    React.useEffect(() => {
        if (selected == null) {
            for (let i = 0; i < keys.length; i++)
                if (datasets?.[keys[i]] != null) {
                    return setSelected(keys[i])
                }
        }
    }, [datasets, keys, selected])

    const data: any = React.useMemo(() => {
        if (datasets == null || selected == null)
            return undefined

        const samples = [...datasets[selected]]
        if (samples.length > 1) {
            const last = samples[samples.length - 1]
            const oneBeforeLast = samples[samples.length - 2]
            const diffTime = last.x - oneBeforeLast.x //ms
            if (diffTime < 3600_000 - 1)
                samples.pop()
        }

        return {
            datasets: [{
                ...commonDatasetOptions,
                data: samples,
                ...datasetsInfo[selected],
                backgroundColor: samples.map(el => findLegendLevel(datasetsInfo[selected].legend, el.y)?.color ?? datasetsInfo[selected].defaultColor ?? gradientColorList[0]),
            }]
        }
    }, [datasets, datasetsInfo, selected])

    const currentInfo = React.useMemo(() => {
        if (selected == null)
            return undefined
        return datasetsInfo?.[selected]
    }, [datasetsInfo, selected])

    const settings = useSettings()
    React.useEffect(() => {
        if (!isTvApp)
            return
        const interval = setInterval(() => {
            let current = undefined
            for (let i = 0; i < info.length; i++) {
                if (info[i].key === selected) {
                    current = i;
                    break;
                }
            }
            if (current != null && !(current >= info.length - 1)) {
                const next = current + 1
                setSelected(info[next].key)
            }

        }, ((settings?.TV_INTERVAL?.value ?? 1000) as number) / (info.length + 1))
        return () => clearInterval(interval)
    }, [info, isTvApp, selected, settings?.TV_INTERVAL])

    if (data == null || data?.datasets?.length === 0)
        return <></>

    return <Box>
        <TooltipHeaderFromContext />
        <Box sx={{ textAlign: 'center', marginTop: 0, marginBottom: 1 }}>
            <ButtonGroup color="primary" size='small' variant="text" aria-label="text button group">
                {info.map(el => <Button sx={{ fontSize: isTvApp ? '0.5rem' : '0.7rem' }} variant={el.key === selected ? 'contained' : 'outlined'} onClick={() => setSelected(el.key)}>{el.label}</Button>)}
            </ButtonGroup>
        </Box>

        {<Bar height={isTvApp ? 60 : undefined} options={options} data={data} />}

        <Box sx={{ display: 'flex', marginTop: 2 }}>
            {currentInfo && currentInfo.legend.map((el, index) =>
                <Tooltip title={el.label} arrow>
                    <Box
                        sx={{ 
                            backgroundColor: el.color, 
                            color: contrastColor(el.color), 
                            textShadow: '0.5px 0.5px 1px rgba(0, 0, 0, 0.1)', 
                            flex: 1, 
                            py: 1, 
                            px: 0, 
                            cursor: 'default', 
                            lineHeight: 'normal', 
                            textAlign: 'center', 
                            borderRadius: (index === 0 ? `5px 0 0 5px` : index === currentInfo.legend.length - 1 ? `0 5px 5px 0` : undefined),
                            fontSize: isTvApp ? '0.5rem' : 9
                        }}
                        key={`legend-${index}`}
                    >
                        {index === 0
                            ? `< ${el.max}`
                            : index === currentInfo.legend.length - 1
                                ? `> ${currentInfo.legend[index - 1].max}`
                                : `< ${el.max}`}
                    </Box>
                </Tooltip>
            )}
        </Box>
    </Box>
}